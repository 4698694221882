import React from 'react'

const OrderProcessIcon  = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="87.964" height="88" viewBox="0 0 87.964 88">
	  <g id="Group_3422" data-name="Group 3422" transform="translate(-879.032 -426)">
	    <path id="Path_16784" data-name="Path 16784" d="M2568.574-11899.686l45.049,3.472h4.85s3.061,3.606,3.649,4.35,2.96-1.976,2.96-1.976l6.1,5.059v4.881l2.925,4.649-2.925,8.685v3.955l-4.936,5.931h-2.67l-2.125-1.491-1.433,4.045h-7.729l-1.642-4.045h-1.689s-3.23,1.751-3.686,1.491-5.32-5.931-5.32-5.931l1.6-3.955-4.033-1.914v-6.771l-29.766-1.107Z" transform="translate(-1675.704 12371.03)" fill="#98dfff"/>
	    <g id="Group_3422-2" data-name="Group 3422" transform="translate(879.032 426)">
	      <rect id="Rectangle_2248" data-name="Rectangle 2248" width="45.671" height="13.367" transform="translate(40.065 25.62)" fill="#fff"/>
	      <rect id="Rectangle_2250" data-name="Rectangle 2250" width="46.785" height="20.051" transform="translate(13.331 45.671)" fill="#fff"/>
	      <g id="product">
	        <path id="Path_16773" data-name="Path 16773" d="M80.232,48.1l-8.26-8.262-3.3,1.979-.93-3.731h-4.3V35H14V56.632H41.812v7.387l3.731.932-1.978,3.3,8.26,8.262,3.3-1.979.93,3.731H67.741l.932-3.733,3.3,1.979,8.26-8.262-1.978-3.3,3.73-.93V52.335L78.252,51.4ZM63.444,58.177A1.545,1.545,0,1,1,61.9,56.632,1.547,1.547,0,0,1,63.444,58.177Zm-3.09-4.635h-30.9v-6.18h30.9Zm-6,3.09h3.2a4.546,4.546,0,0,0-.286,1.545,4.635,4.635,0,1,0,6.18-4.351V50.607a7.726,7.726,0,1,1-9.271,7.57A7.5,7.5,0,0,1,54.352,56.632Zm6-12.361h-30.9V38.09h30.9ZM26.361,38.09v6.18H17.09V38.09ZM17.09,47.361h9.271v6.18H17.09Zm61.8,7.387v6.857l-3.011.752-.825.312-.718,1.765,2,3.337-4.85,4.849-3.336-2-1.885.777-.944,3.778H58.47l-.944-3.779-1.885-.777-3.336,2-4.85-4.849,2-3.337-.766-1.882L44.9,61.605V56.632h6.315a10.588,10.588,0,0,0-.134,1.545A10.816,10.816,0,1,0,63.444,47.485v-6.3h1.883l.944,3.779,1.885.777,3.336-2,4.85,4.849-2,3.334.766,1.885Z" transform="translate(-1.747 9.737)" fill="#3490b9"/>
	        <path id="Path_16774" data-name="Path 16774" d="M31,21V36.451H80.444V21Zm3.09,3.09h9.271v9.271H34.09Zm43.263,9.271h-30.9V24.09h30.9Z" transform="translate(7.52 4.333)" fill="#3490b9"/>
	        <path id="Path_16778" data-name="Path 16778" d="M56.444,1H7V22.632H56.444Zm-3.09,9.271h-30.9V4.09h30.9ZM19.361,4.09v6.18H10.09V4.09ZM10.09,13.361h9.271v6.18H10.09Zm12.361,6.18v-6.18h30.9v6.18Z" transform="translate(-5.563 -1)" fill="#3490b9"/>
	        <path id="Path_16779" data-name="Path 16779" d="M49,25h3.09v3.09H49Z" transform="translate(17.332 6.513)" fill="#3490b9"/>
	        <path id="Path_16780" data-name="Path 16780" d="M53,25h3.09v3.09H53Z" transform="translate(19.513 6.513)" fill="#3490b9"/>
	        <path id="Path_16781" data-name="Path 16781" d="M57,25h3.09v3.09H57Z" transform="translate(21.693 6.513)" fill="#3490b9"/>
	        <path id="Path_16783" data-name="Path 16783" d="M60.451,11.635v4.635h-6.18L62,26.572l7.726-10.3h-6.18V11.635A4.641,4.641,0,0,0,58.906,7H45v3.09H58.906A1.547,1.547,0,0,1,60.451,11.635ZM62,21.421l-1.545-2.06h3.09Z" transform="translate(9.582 -6.641)" fill="#3490b9"/>
	        <path id="Path_16785" data-name="Path 16785" d="M54.271,11.635v4.635h6.181l-7.726,10.3L45,16.271h6.18V11.635A4.641,4.641,0,0,1,55.816,7H69.722v3.09H55.816A1.547,1.547,0,0,0,54.271,11.635Zm-1.545,9.785,1.545-2.06H51.18Z" transform="translate(-45 20.093)" fill="#3490b9"/>
	      </g>
	    </g>
	  </g>
	</svg>
)

export default OrderProcessIcon