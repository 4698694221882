import React from "react"
import styled from "styled-components"
import FlipPage from "react-flip-page"

import RightBorder from "../../images/right-border.png"
import LeftBorder from "../../images/left-border.png"

import VerticalRoofIcon from "../../components/Icons/VerticalRoofIcon"
import FrameRoofIcon from "../../components/Icons/FrameRoofIcon"
import RegularRoofIcon from "../../components/Icons/RegularRoofIcon"

import OrderProcessIcon from "../../components/Icons/OrderProcessIcon"
import ArrowIcon from "../../components/Icons/ArrowIcon"

import { RedLink } from "../../components/Link"

import FinancingImg from "../../images/finance-guide.png"
import RtoImg from "../../images/rto-guide.png"
import SettingImg from "../../images/settingIcon.png"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { HeroTitle } from "../../components/Section"

const RoofContainer = styled.div`
  background: white;
  padding: 50px 20px;
  border-radius: 10px;
  @media (max-width: 1200px) {
    display: none;
  }
`
const RoofGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  align-items: center;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, auto);
  }
`
const TextPart = styled.div`
  position: relative;
  padding: 15px 0 5px;
  height: 100%;
  margin-right: -100px;
  padding-right: 80px;
`
const ImagePart = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 1200px) {
    display: none;
  }
`
const Image = styled.div`
  width: 88px;
  display: inline-block;
  vertical-align: middle;
  @media (max-width: 1200px) {
    display: none;
  }
`
const Title = styled.div`
  width: calc(100% - 118px);
  margin-left: 30px;
  display: inline-block;
  vertical-align: middle;
  color: ${props => props.color};
  margin-top: ${props => props.mt};
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  & h1 {
    margin-bottom: 0;
  }
`
const Wrapper = styled.div`
  background: #f4fbff;
  padding-top: 200px;
  @media (max-width: 1300px) {
    padding-top: 130px;
  }
  @media (max-width: 480px) {
    padding-top: 110px;
  }
`
const BookWrapper = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border: 1px solid #98dfff;
  box-shadow: 0px 10px 40px rgba(152, 223, 255, 0.5);
`
const LeftPannel = styled.div`
  background: linear-gradient(to right, white 80%, rgba(0, 0, 0, 0.06) 100%);
  padding: 40px 0px 40px 40px;
  border-right: 1px solid #eee;
  height: 100%;
`
const RightPannel = styled.div`
  background: white;
  padding: 40px 40px 40px 0px;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1) 1%,
    rgba(0, 0, 0, 0.05) 5%,
    white 30%
  );
`
const LeftTextContainer = styled.div`
  padding: ${props => props.py} 50px;
  background: linear-gradient(
    to right,
    rgba(235, 248, 255, 1),
    #ebf8ff 80%,
    rgba(0, 0, 0, 0.06) 100%
  );
  height: 100%;
  & p {
    margin-bottom: ${props => props.mb};
  }
  @media (max-width: 1280px) {
    padding: ${props => props.py} 30px;
    & h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }
`
LeftTextContainer.defaultProps = {
  py: "10%",
}

const RightTextContainer = styled.div`
  padding: ${props => props.py} 50px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.05) 5%,
    #ebf8ff 50%,
    #ebf8ff 100%
  );
  height: 100%;

  & h3 {
    margin: 5px 0px 10px;
  }
  & p {
    margin-bottom: ${props => props.mb};
  }
  @media (max-width: 1280px) {
    padding: ${props => props.py} 30px;
    & h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }
`
RightTextContainer.defaultProps = {
  py: "10%",
}
const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: ${props => props.mb};
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 400px) {
    margin-top: 10px;
    margin-bottom: 5px;
  }
`
Label.defaultProps = {
  mb: "20px",
}

const NavItem = styled.h4`
  font-size: 20px;
  line-height: 24px;
  color: #1e4156;
  margin-bottom: 0px;
  & :hover {
    cursor: pointer;
  }
  & a {
    color: #1e4156;
    text-decoration: none;
  }
  & svg {
    float: right;
    margin-top: 8px;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-bottom: 1px solid rgba(1, 9, 32, 0.1);
  @media (max-width: 480px) {
    margin: ${props => props.mmy} 0;
  }
`
Divider.defaultProps = {
  mmy: "20px",
}
const ProgressBar = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 480px) {
    transform: translateX(${props => props.tx});
  }
  @media (max-width: 767.98px) {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
`
const ProgressItem = styled.div`
  position: relative;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-bottom: 4px solid ${props => props.bbcolor};
  text-align: center;
  color: ${props => props.color};
  & div {
    width: 2px;
    height: 12px;
    border-left: 2px solid #ed602b;
    position: absolute;
    bottom: 0px;
    left: 50%;
  }
  & :hover {
    cursor: pointer;
  }
`
ProgressItem.defaultProps = {
  bbcolor: "#98DFFF",
  color: "676B79",
}

const LeftImageBorder = styled.img`
  position: absolute;
  height: 90%;
  left: ${props => props.left};
  display: ${props => props.display};
  top: 0;
  @media (max-width: 1400px) {
    height: 88%;
  }
  @media (max-width: 1000px) {
    height: 86%;
  }
  @media (max-width: 768px) {
    height: 79.5%;
    right: -8px;
  }
`
LeftImageBorder.defaultProps = {
  left: "-12px",
}

const RightImageBorder = styled.img`
  position: absolute;
  height: 90%;
  right: ${props => props.right};
  display: ${props => props.display};
  top: 0;
  @media (max-width: 1400px) {
    height: 88%;
  }
  @media (max-width: 1000px) {
    height: 86%;
  }
  @media (max-width: 768px) {
    height: 79.5%;
    right: -8px;
  }
`
RightImageBorder.defaultProps = {
  right: "-12px",
}

const DesctopFlip = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`

const MobileFlip = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
const MobileTextContainer = styled.div`
  border: 1px solid #98dfff;
  box-shadow: 0px 10px 40px rgba(152, 223, 255, 0.5);
  padding: ${props => props.py} 15px;
  height: 100%;
  @media (max-width: 480px) {
    & h4 {
      font-size: 18px;
      line-height: 22px;
    }
    & p {
      margin-bottom: 10px;
    }

    & a {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 370px) {
    & h1 {
      font-size: 20px !important;
      margin-bottom: 0;
    }
    & h1 {
      font-size: 18px !important;
    }
    & h4 {
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.7px;
    }
    & svg {
      margin-top: 5px;
    }
    & p {
      font-size: 15px;
      margin-bottom: 0;
    }
  }
`

class BuyingGuide extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentFlip: "0",
    }
    this.changeCurrentFlip = this.changeCurrentFlip.bind(this)
  }
  changeCurrentFlip(data) {
    this.setState({
      currentFlip: data,
    })
  }
  render() {
    const { currentFlip } = this.state
    return (
      <Layout location={this.props.location}>
        <SEO title="Buying Guide" description="Buying Guide Page" />
        <Wrapper>

          <HeroTitle maxWidth="100%">Metal Building Buying Guide</HeroTitle>
          <div className="container">
            <DesctopFlip>
              {typeof window !== "undefined" && FlipPage && (
                <FlipPage
                  width="100%"
                  height={700}
                  orientation="horizontal"
                  onPageChange={this.changeCurrentFlip}
                  animationDuration="1000"
                  flipOnLeave
                  ref={component => {
                    this.flipPage = component
                  }}
                >
                  <BookWrapper>
                    <LeftPannel>
                      <LeftTextContainer py="30%">
                        <h2 className="h1">Buying Guide</h2>
                        <p>
                        When planning a metal building, there are many factors to consider apart from style and color. You need to decide what components you require, how to order them, the type of roof style you want, and how to prepare your site for installation. However, financing options also play an important role in making the right decision. To help you explore all these aspects, we have created a buying guide that will help you make the right decision for your metal building project.</p>
                      </LeftTextContainer>
                    </LeftPannel>
                    <RightPannel>
                      <RightTextContainer py="25%">
                        <Label>Navigation</Label>
                        <NavItem onClick={() => this.flipPage.gotoPage(1)}>Metal Building Roof Guide <ArrowIcon fill="#ED602B" /></NavItem>
                        <Divider />
                        <NavItem onClick={() => this.flipPage.gotoPage(2)}>Financing Option Available <ArrowIcon fill="#ED602B" /></NavItem>
                        <Divider />
                        <NavItem onClick={() => this.flipPage.gotoPage(3)}>Components & Order Process <ArrowIcon fill="#ED602B" /></NavItem>
                        <Divider />
                        <NavItem onClick={() => this.flipPage.gotoPage(4)}>Site Preparation & Permit Guide <ArrowIcon fill="#ED602B" /></NavItem>
                      </RightTextContainer>
                    </RightPannel>
                  </BookWrapper>

                  <BookWrapper>
                    <LeftPannel>
                      <LeftTextContainer py="15%">
                        <h2 className="h1">Metal Building Roof Guide</h2>
                        <p>Deciding on the style roof you want for your metal building varies depending on a number of factors. Site location, region, plans for use, as well as price.Our most popular roof styles are the Regular, A-Frame, and Vertical. Each style offers its own unique features visually, economically, and functionality. Finding the right roof style is key. Our building specialists are here to help you through each step of the process.</p>
                        <RedLink to="/buying-guide/metal-building-roof-guide">Know More <ArrowIcon fill="#ED602B" /></RedLink>
                      </LeftTextContainer>
                    </LeftPannel>
                    <RightPannel>
                      <RightTextContainer py="13%">
                        <RoofGrid>
                          <RoofContainer><VerticalRoofIcon /></RoofContainer>
                          <div>
                            <Label mb="0px">Best Quality</Label>
                            <div className="h3">Vertical Roof</div>
                            <p>
                              Vertical Roofing, the popular choice for strength
                              and durability.
                            </p>
                          </div>
                          <RoofContainer>
                            <FrameRoofIcon />
                          </RoofContainer>
                          <div>
                            <Label mb="0px">Best Quality</Label>
                            <div className="h3">A-Frame Roof</div>
                            <p>
                              A-frame roofing, is affordable, versatile, and
                              long-lasting.
                            </p>
                          </div>
                          <RoofContainer>
                            <RegularRoofIcon />
                          </RoofContainer>
                          <div>
                            <Label mb="0px">Good Quality</Label>
                            <div className="h3">Regular Roof</div>
                            <p>
                              Regular Roofing is simple, straight forward and
                              economical.
                            </p>
                          </div>
                        </RoofGrid>
                      </RightTextContainer>
                    </RightPannel>
                  </BookWrapper>
                  <BookWrapper>
                    <LeftPannel>
                      <LeftTextContainer py="20%">
                        <h2 className="h1">Financing Option Available</h2>
                        <p style={{ marginBottom: "30px" }}>
                          Have you designed your dream steel structure, but now
                          you’re experiencing sticker shock? Buying a building
                          is an investment. At Probuilt steel buildings, we
                          offer financing options to help meet your budget and
                          your needs.
                        </p>
                      </LeftTextContainer>
                    </LeftPannel>
                    <RightPannel>
                      <RightTextContainer py="15%">
                        <RoofGrid>
                          <TextPart>
                            <div className="h3">Financing</div>
                            <p>
                              Financing with Probuilt Steel Buildings is quick,
                              simple, with no hidden fees!
                              <br />
                              <RedLink to="/buying-guide/financing-process/">
                                read more
                              </RedLink>
                            </p>
                          </TextPart>
                          <ImagePart>
                            <img src={FinancingImg} alt="financing" />
                          </ImagePart>
                          <TextPart>
                            <div className="h3">Rent to Own (RTO)</div>
                            <p>
                              Rent-to-own programs are one of the easiest,
                              quickest, stress-free ways to get your metal
                              building as soon as possible without having to pay
                              for the building in one large payment. <br />
                              <RedLink to="/buying-guide/rto-process/">
                                read more
                              </RedLink>
                            </p>
                          </TextPart>
                          <ImagePart>
                            <img src={RtoImg} alt="rent to own" />
                          </ImagePart>
                        </RoofGrid>
                      </RightTextContainer>
                    </RightPannel>
                  </BookWrapper>
                  <BookWrapper>
                    <LeftPannel>
                      <LeftTextContainer py="20%">
                        <div>
                          <Image>
                            <img
                              src={SettingImg}
                              alt="Steel Building Components"
                            />
                          </Image>
                          <Title>
                            <h2 className="h1">Steel Building Components</h2>
                          </Title>
                        </div>
                        <p style={{ margin: "30px 0" }}>
                          Every component in your new steel building is
                          important. Each component is manufactured using only
                          the best commercial grade U.S. steel. At every stage
                          of production careful attention is paid to ensure the
                          quality and integrity of our steel buildings.
                        </p>
                        <RedLink to="/buying-guide/steel-building-components">
                          Know More <ArrowIcon fill="#ED602B" />
                        </RedLink>
                      </LeftTextContainer>
                    </LeftPannel>
                    <RightPannel>
                      <RightTextContainer py="20%">
                        <Image>
                          <OrderProcessIcon />
                        </Image>
                        <Title>
                          <h2 className="h1">Order/Installation Process</h2>
                        </Title>
                        <p style={{ margin: "30px 0" }}>
                          Buying your new custom metal building is easy with
                          Probuilt Steel Buildings! You can design your own
                          custom metal building with our 3D Modeler and submit
                          your request online.
                        </p>
                        <RedLink to="/buying-guide/order-installation-process">
                          Know More <ArrowIcon fill="#ED602B" />
                        </RedLink>
                      </RightTextContainer>
                    </RightPannel>
                  </BookWrapper>
                  <BookWrapper>
                    <LeftPannel>
                      <LeftTextContainer py="20%">
                        <h2 className="h1">Site Preparation Guide</h2>
                        <p style={{ margin: "30px 0" }}>
                          Site selection and preparation is imperative to the
                          quality of the installation of your steel building. By
                          eliminating any debris or brush and properly leveling
                          the site, the installers will be able to correctly
                          assemble and erect your new steel building.
                        </p>
                        <RedLink to="/buying-guide/site-preparation-guide">
                          Know More <ArrowIcon fill="#ED602B" />
                        </RedLink>
                      </LeftTextContainer>
                    </LeftPannel>
                    <RightPannel>
                      <RightTextContainer py="20%">
                        <h2 className="h1">Permit & Engineering Guide</h2>
                        <p style={{ margin: "30px 0" }}>
                          Before any building is delivered and installed, be
                          sure to check with your local building departments to
                          see if you need any special building permits. If a
                          permit is required, most counties and cities have
                          websites your can visit that will have all the
                          necessary paperwork and applications for permit.
                        </p>
                        <RedLink to="/buying-guide/permit-engineering-guide">
                          Know More <ArrowIcon fill="#ED602B" />
                        </RedLink>
                      </RightTextContainer>
                    </RightPannel>
                  </BookWrapper>
                </FlipPage>
              )}
            </DesctopFlip>
            <MobileFlip>
              {typeof window !== "undefined" && FlipPage && (
                <FlipPage
                  width="100%"
                  height={450}
                  orientation="horizontal"
                  onPageChange={this.changeCurrentFlip}
                  animationDuration="1000"
                  ref={component => {
                    this.flipMobilePage = component
                  }}
                >
                  <MobileTextContainer py="30px">
                    <Label>Description</Label>
                    <h2 className="h1">Metal Building Buying Guide</h2>
                    <Divider />
                    <Label>Navigation</Label>
                    <NavItem onClick={() => this.flipMobilePage.gotoPage(1)}>
                      {" "}
                      Metal Building Roof Guide <ArrowIcon fill="#ED602B" />
                    </NavItem>
                    <Divider />
                    <NavItem onClick={() => this.flipMobilePage.gotoPage(2)}>
                      {" "}
                      Financing Option Available <ArrowIcon fill="#ED602B" />
                    </NavItem>
                    <Divider />
                    <NavItem onClick={() => this.flipMobilePage.gotoPage(3)}>
                      {" "}
                      Components & Order Process <ArrowIcon fill="#ED602B" />
                    </NavItem>
                    <Divider />
                    <NavItem onClick={() => this.flipMobilePage.gotoPage(4)}>
                      {" "}
                      Site Preparation & Permit Guide{" "}
                      <ArrowIcon fill="#ED602B" />
                    </NavItem>
                  </MobileTextContainer>
                  <MobileTextContainer py="20%">
                    <h2 className="h1">Metal Building Roof Guide</h2>
                    <p>
                      Deciding on the style roof you want for your metal
                      building varies depending on a number of factors. Site
                      location, region, plans for use, as well as price. Our
                      most popular roof styles are the Regular, A-Frame, and
                      Vertical. Each style offers its own unique features
                      visually, economically, and functionality. Finding the
                      right roof style is key. Our building specialists are here
                      to help you through each step of the process.
                    </p>
                    <RedLink to="/buying-guide/metal-building-roof-guide">
                      Know More <ArrowIcon fill="#ED602B" />
                    </RedLink>
                  </MobileTextContainer>
                  <MobileTextContainer py="20px">
                    <h2 className="h1">Financing Option Available</h2>
                    <p>
                      Have you designed your dream steel structure, but now
                      you’re experiencing sticker shock? Buying a building is an
                      investment. At Probuilt steel buildings, we offer
                      financing options to help meet your budget and your needs.
                    </p>
                    <Divider mmy="10px" />
                    <div className="h3">Financing</div>
                    <p>
                      Financing with Probuilt Steel Buildings is quick, simple,
                      with no hidden fees!
                      <br />
                      <RedLink to="/buying-guide/financing-process/">
                        read more
                      </RedLink>
                    </p>
                    <div className="h3">Rent to Own (RTO)</div>
                    <p>
                      Rent-to-own programs are one of the easiest, quickest,
                      stress-free ways to get your metal building as soon as
                      possible without having to pay for the building in one
                      large payment.
                      <br />
                      <RedLink to="/buying-guide/rto-process/">
                        read more
                      </RedLink>
                    </p>
                  </MobileTextContainer>
                  <MobileTextContainer py="25px">
                    <h2 className="h1">Steel Building Components</h2>
                    <p>
                      Every component in your new steel building is important.
                      Each component is manufactured using only the best
                      commercial grade U.S. steel. At every stage of production
                      careful attention is paid to ensure the quality and
                      integrity of our steel buildings.
                    </p>
                    <RedLink to="/buying-guide/steel-building-components">
                      Know More <ArrowIcon fill="#ED602B" />
                    </RedLink>
                    <Divider mmy="10px" />
                    <h2 className="h1">Order/Installation Process</h2>
                    <p>
                      Buying your new custom metal building is easy with
                      Probuilt Steel Buildings! You can design your own custom
                      metal building with our 3D Modeler and submit your request
                      online.
                    </p>
                    <RedLink to="/buying-guide/order-installation-process">
                      Know More <ArrowIcon fill="#ED602B" />
                    </RedLink>
                  </MobileTextContainer>
                  <MobileTextContainer py="25px">
                    <h2 className="h1">Site Preparation Guide</h2>
                    <p>
                      Site selection and preparation is imperative to the
                      quality of the installation of your steel building. By
                      eliminating any debris or brush and properly leveling the
                      site, the installers will be able to correctly assemble
                      and erect your new steel building.
                    </p>
                    <RedLink to="/buying-guide/site-preparation-guide">
                      Know More <ArrowIcon fill="#ED602B" />
                    </RedLink>
                    <Divider mmy="10px" />
                    <h2 className="h1">Permit & Engineering Guide</h2>
                    <p>
                      Before any building is delivered and installed, be sure to
                      check with your local building departments to see if you
                      need any special building permits. If a permit is
                      required, most counties and cities have websites your can
                      visit that will have all the necessary paperwork and
                      applications for permit.
                    </p>
                    <RedLink to="/buying-guide/permit-engineering-guide">
                      Know More <ArrowIcon fill="#ED602B" />
                    </RedLink>
                  </MobileTextContainer>
                </FlipPage>
              )}
            </MobileFlip>
            <LeftImageBorder
              src={LeftBorder}
              display={currentFlip === 4 ? "block" : "none"}
            />
            <RightImageBorder
              src={RightBorder}
              display={currentFlip === 4 ? "none" : "block"}
            />
            <ProgressBar tx={currentFlip > 2 ? "-250px" : "0"}>
              <ProgressItem
                color={currentFlip >= 0 ? "#ED602B" : "#676B79"}
                bbcolor={currentFlip >= 0 ? "#ED602B" : "#98DFFF"}
                onClick={() => this.flipPage.gotoPage(0)}
              >
                Buying Guide Description & Navigaion
                <div></div>
              </ProgressItem>
              <ProgressItem
                color={currentFlip >= 1 ? "#ED602B" : "#676B79"}
                bbcolor={currentFlip >= 1 ? "#ED602B" : "#98DFFF"}
                onClick={() => this.flipPage.gotoPage(1)}
              >
                Metal Building Roof Guide
                <div></div>
              </ProgressItem>
              <ProgressItem
                color={currentFlip >= 2 ? "#ED602B" : "#676B79"}
                bbcolor={currentFlip >= 2 ? "#ED602B" : "#98DFFF"}
                onClick={() => this.flipPage.gotoPage(2)}
              >
                Financing Option Available
                <div></div>
              </ProgressItem>
              <ProgressItem
                color={currentFlip >= 3 ? "#ED602B" : "#676B79"}
                bbcolor={currentFlip >= 3 ? "#ED602B" : "#98DFFF"}
                onClick={() => this.flipPage.gotoPage(3)}
              >
                Components & Order Process
                <div></div>
              </ProgressItem>
              <ProgressItem
                color={currentFlip >= 4 ? "#ED602B" : "#676B79"}
                bbcolor={currentFlip >= 4 ? "#ED602B" : "#98DFFF"}
                onClick={() => this.flipPage.gotoPage(4)}
              >
                Site Preparation & Permit Guide
                <div></div>
              </ProgressItem>
            </ProgressBar>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

export default BuyingGuide
